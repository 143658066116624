import React from "react"
import "../../scss/index.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../../components/layout/layout"
import Header from "../../components/layout/header"
import Footer from "../../components/layout/footer"
import CaseStudy from "../../../static/img/case-study.jpg"
import { Helmet } from "react-helmet"
import HeadBlock from "../../components/head-block/head-block";

export default function Home() {
  return <>
  <Helmet>
          <meta charSet="utf-8" />
          <title>Eco Wash Solutions | Anywhere Anytime We Come To You</title>
          <link rel="canonical" href="https://ecocarwash.co.nz" />
        </Helmet>
  <Header />
  <Layout>
  {/* Head Block */}
  <HeadBlock banner={CaseStudy} pretitle="Modern Edquipment" title="Case Study - Fulton Hogan" description="Anywhere Anytime We Come to You"  />
   {/* Intro Block */}
   <div className="container">
      <h3 className="heading">Eco Wash Solutions Saves the Day with Innovative Paint Correction Solution</h3>
      <h6 className="sub-heading sub-heading--basic">Background:</h6>
      <p>In 2019, Eco Wash Solutions was contacted by Fulton Hogan, a leading infrastructure company, with a unique challenge. During a roading project, windy conditions caused lime to splash over almost 30 cars parked nearby, resulting in spots on the paint. The company had tried to rectify the issue themselves, but with little success.</p>
      <h6 className="sub-heading sub-heading--basic">Solution:</h6>
      <p>Eco Wash Solutions provided a solution that involved a paint correction technique using a clay bar. The process involved gently rubbing the clay bar over the affected areas, which removed the spots without causing any further damage to the paint. The Eco Wash Solutions team worked efficiently and diligently to complete the project in a timely manner.</p>
      <h6 className="sub-heading sub-heading--basic">Result:</h6>
      <p>Thanks to the innovative solution provided by Eco Wash Solutions, the 30 cars were restored to their original condition without the need for a costly repainting job. The paint correction process using a clay bar saved the car owners a significant amount of money and ensured that the cars looked as good as new.</p>
      <h6 className="sub-heading sub-heading--basic">Conclusion:</h6>
      <p>: This case study highlights the value of Eco Wash Solutions' innovative approach to paint correction. By providing a cost-effective and efficient solution, the company was able to restore the cars to their original condition and save the car owners a considerable amount of money. The Eco Wash Solutions team's expertise and dedication to delivering quality work ensured that the project was completed successfully and in a timely manner. As a result, the company has established itself as a reliable and trusted partner for any paint correction project.</p>
      <AnchorLink to="/#contact" title="Contact Us" className="button button--large button--primary">Get in touch for a customised quote</AnchorLink>
      </div>
  </Layout>
  <Footer copyright="Copyright &copy; 2020 - 2023 Eco Wash Solutions. All Rights Reserved" />
  </>
}
